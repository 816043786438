import { sendErrorToSentry } from '@/thirdParties/sentry'
import { Banner, Button, Header, Modal } from '@alma/react-components'
import React, { useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import MigrationModalHeader from '@/components/Pages/Home/CreatePaymentPage/MigrationModalHeader.png'
import s from '@/components/Pages/Home/CreatePaymentPage/MigrationModal.module.css'
import { useTracking } from '@/thirdParties/analytics/useTracking'

const IS_TEST = process.env.NODE_ENV === 'test' || process.env.NODE_ENV === 'storybook'

export const MigrationModal: React.FC = () => {
  const [displayModal, setDisplayModal] = React.useState(false)
  const track = useTracking('home')

  const setHasSeen = () => {
    try {
      localStorage.setItem('hasSeenMigrationModal', 'true')
    } catch (e) {
      // Prevent crash if storage is disabled.
      sendErrorToSentry(new Error(`Writing to localStorage failed in MigrationModal.`), {
        level: 'error',
        fingerprint: ['unknown-step'],
      })
    }
    setDisplayModal(false)
  }

  useEffect(() => {
    try {
      if (!localStorage.getItem('hasSeenMigrationModal') && !IS_TEST) {
        setDisplayModal(true)
        track('migration_modal_displayed')
      }
    } catch (e) {
      // Prevent crash if storage is disabled.
      sendErrorToSentry(new Error(`Reading localStorage failed in MigrationModal.`), {
        level: 'error',
        fingerprint: ['unknown-step'],
      })
    }
  }, [track])

  return (
    <Modal size="lg" isOpen={displayModal} onClose={setHasSeen}>
      <div className={s.container}>
        <img src={MigrationModalHeader} alt="" width="100%" />

        <div className={s.content}>
          <Header size="lg">
            <FormattedMessage
              id="migrationModal.discover-title"
              defaultMessage="Discover your new store interface!"
              description="This is the header of the discover modal."
            />
          </Header>
          <div>
            <p>
              ✅ &nbsp;
              <FormattedMessage
                id="migrationModal.paymentStatusTracking"
                defaultMessage="Real-time payment status tracking."
                description="Modal explanation on new feature, this one concerns : Real-time payment status tracking."
              />
            </p>
            <p>
              🔍 &nbsp;
              <FormattedMessage
                id="migrationModal.paymentSearchAndReports"
                defaultMessage="Payment search and detailed report generation."
                description="Modal explanation on new feature, this one concerns : Payment search and detailed report generation."
              />
            </p>
            <p>
              👌 &nbsp;
              <FormattedMessage
                id="migrationModal.betterOverallHandling"
                defaultMessage="Better overall handling."
                description="Modal explanation on new feature, this one concerns : Better overall handling."
              />
            </p>
          </div>
          <Banner
            type="info"
            title={
              <FormattedMessage
                id="migrationModal.banner"
                defaultMessage="If you have bookmarked this page, please update it with the new URL: shop.getalma.eu."
                description="Banner displayed inside the migration modal."
              />
            }
          />
        </div>
        <Button onClick={setHasSeen} block>
          <FormattedMessage
            id="migrationModal.discover-button"
            defaultMessage="Discover"
            description="This is the button to discover the new features from the migration modal. It close the modal."
          />
        </Button>
      </div>
    </Modal>
  )
}
